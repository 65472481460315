import $ from "jquery";
import './jquery.youtube-background.js';
import './lity.min.js';
import './strip.pkgd.js';

let documentSrc = document.currentScript.src;

function initVideo($element, settings) {
    $element = $($element);
    adjustElement($element, settings)
    if (settings.isPreview) {
        $('<img>', {
            src: settings.src,
            alt: settings.url
        }).appendTo($element);
        $element = $element.find('img');
    }

    let altText = $element.attr('alt');
    settings.url = altText;
    settings.classString = $element.attr('class');

    if (!altText) {
        return;
    }

    $(document).on('lity:open', function(event, instance) {
        $('.vw-product-video').trigger('play');
    });

    $(document).on('lity:close', function(event, instance) {
        $('.vw-product-video').trigger('pause');
    });

    if (altText.startsWith('https://www.youtube.com')) {
        let elementRatio = $element.width() / $element.height();
        applyClassToParents($element);
        let id = '';
        try {
            id = altText.split('?')[1].split('&')[0].split('=')[1];
        } catch (e) {
            id = altText.split('/').pop();
        }
        /*if (settings.display_type === 'minimal') {
            let $ytDiv = $('<div>',{
                'data-youtube': 'https://www.youtube.com/watch?v=' + id,
                'data-ytbg-play-button': settings.show_controls,
                'data-ytbg-mute-button': settings.show_controls,
                'data-ytbg-autoplay': settings.autoplay,
                'data-ytbg-muted': settings.mute,
                text: 'Try it out'
            });
            $element.replaceWith($ytDiv);
        } else */
        let optionString = getYouTubeOptions(settings, id).optionString;
        let options = getYouTubeOptions(settings, id).options;
        
        if (settings.display_type === 'inline') {
            let $ytFrame = getInlineiFrame('https://www.youtube.com/embed/' + id + optionString, settings);
            $ytFrame.attr('id', 'yt-' + id);
            $ytFrame.addClass('vw-yt-iframe');
            $element.parent().css('margin-top', '10px');
            $element.parent().css('margin-bottom', '10px');
            $element.replaceWith($ytFrame);
        } else if (settings.display_type === 'lightbox') {
            let $anchor = $('<a>', {
                href: 'https://www.youtube.com/watch?v=' + id + optionString,
                'data-lity': '',
                class: 'vw-video-anchor-link'
            });
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
            $('.vw-product-video').trigger('pause');
        } else if (settings.display_type === 'sidebox') {
            let $anchor = $('<a>', {
                href: 'https://www.youtube.com/watch?v=' + id + optionString,
                class: 'strip vw-video-anchor-link',
                'data-strip-options': 'youtube:' + JSON.stringify(options)
            });
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
        }
    } else if (altText.startsWith('https://vimeo.com/')) {
        let elementRatio = $element.width() / $element.height();
        applyClassToParents($element);
        let splitAlt = altText.split('/');
        let id = splitAlt[splitAlt.length-1];
        let optionString = getVimeoOptions(settings).optionString;
        if (splitAlt.length === 5) {
            id = splitAlt[splitAlt.length-2];
            optionString += '&h=' + splitAlt[splitAlt.length-1];
        }
        let options = getVimeoOptions(settings).options;
        if (settings.display_type === 'inline') {
            let $vimeoFrame = getInlineiFrame('https://player.vimeo.com/video/' + id + optionString, settings);
            $element.replaceWith($vimeoFrame);
        } else if (settings.display_type === 'lightbox') {
            let $anchor = $('<a>', {
                href: 'https://player.vimeo.com/video/' + id + optionString,
                'data-lity': '',
                class: 'vw-video-anchor-link'
            });
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
            $('.vw-product-video').trigger('pause');
        } else if (settings.display_type === 'sidebox') {
            let $anchor = $('<a>', {
                href: 'https://vimeo.com/' + id + optionString,
                class: 'strip vw-video-anchor-link',
                'data-strip-options': 'vimeo: ' + JSON.stringify(options)
            });
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
        }
    } else if (altText.startsWith('https://') && (altText.toLowerCase().indexOf('.mp4') !== -1 || altText.toLowerCase().indexOf('.mov') !== -1)) {
        let elementRatio = $element.width() / $element.height();
        applyClassToParents($element);
        if (settings.display_type === 'inline') {
            let $video = getVideoElement(settings);
            $element.replaceWith($video);
        } else if (settings.display_type === 'lightbox') {
            let $video = getVideoElement(settings);
            let videoid = makeid(10);
            let $videoDiv = $('<div>', {
                id: videoid,
                class: 'lity-hide'
            });
            $videoDiv.css('max-width', '960px');
            applyWidthToVideoDiv($videoDiv, elementRatio);
            $video.appendTo($videoDiv);
            $videoDiv.appendTo($element.parent());
            let $anchor = $('<a>', {
                href: '#' + videoid ,
                'data-lity': '',
                class: 'vw-video-anchor-link'
            });
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $video.on("loadedmetadata", function() {
                $videoDiv.css('min-height', this.videoHeight / this.videoWidth * Math.min(this.videoWidth, 960));
            });
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
            $('.vw-product-video').trigger('pause');
        } else if (settings.display_type === 'sidebox') {
            let videoid = makeid(10);
            let $anchor = $('<a>', {
                href: '#' + videoid,
                class: 'strip vw-video-anchor-link',
                'data-strip-type': 'id'
            });
            let $video = getVideoElement(settings);
            let $videoDiv = $('<div>', {
                id: videoid,
                class: 'lity-hide'
            });
            $videoDiv.css('width', '100%');
            applyWidthToVideoDiv($videoDiv, elementRatio);
            $video.appendTo($videoDiv);
            $videoDiv.appendTo($element.parent());
            applyAnchorCSS($anchor);
            getPlayButton(settings).appendTo($anchor);
            let $imageElement = getImageElement($element);
            $imageElement.appendTo($anchor);
            $element.replaceWith($anchor);
            $imageElement.css('min-height', $imageElement.width() / elementRatio);
            $('.vw-product-video').trigger('pause');
        }
    }

    window.Strip.enable();
    /*if (settings.isPreview && settings.display_type === 'minimal'){
        $('[data-youtube]').youtube_background({
            'load-background':false,
        });
    }*/
}

function applyClassToParents($element) {
    $element.parent().addClass('product-video-container');
    if ($element.parent().parent().hasClass('Product__SlideItem--image')) {
        $element.parent().parent().addClass('product-video-container');
    }
}

function applyWidthToVideoDiv($videoDiv, elementRatio) {
    if (elementRatio) {
        if (elementRatio < 1) {
            $videoDiv.css('width', elementRatio * $(window).height());
            $videoDiv.css('margin', '0 auto');
        }
    }
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

function getVideoElement(settings) {
    let $video = $('<video>', getVideoElementOptions(settings));
    $video.attr('playsinline','');
    if (settings.autoplay) {
        $video.attr('autoplay','');
    }
    if (settings.mute) {
        $video.attr('muted','');
    }
    if (settings.show_controls) {
        $video.attr('controls','controls');
    }
    $video.css('width', '100%');
    $video.attr('class', 'vw-product-video');
    $('<source>', {
        src: settings.url,
        type: 'video/mp4'
    }).appendTo($video);
    return $video;
}

function getVideoElementOptions(settings) {
    let options = {
        oncanplay: ''
    };
    if (settings.mute) {
        options.oncanplay += 'this.muted=true;';
    }
    if (settings.autoplay) {
        options.autoplay = '';
        if (settings.display_type === 'inline') {
            options.oncanplay += 'this.play();';
        }
    }
    if (settings.show_controls) {
        options.controls = '';
    }
    if (settings.loop) {
        options.loop = '';
    }
    return options;
}

function getYouTubeOptions(settings, id) {
    let optionString = '?';
    let options = {
        autoplay: settings.autoplay ? '1' : '0',
        mute: settings.mute ? '1' : '0',
        controls: settings.show_controls ? '1' : '0',
        loop: settings.loop ? '1': '0',
        playsinline: settings.autoplay ? '1': '0',
        modestbranding: '1',
        enablejsapi: '1',
        playlist: id
    }
    for (let k of Object.keys(options)) {
        optionString += k + '=' + options[k] + '&';
    }
    return {optionString:optionString, options:options};
}


function getVimeoOptions(settings) {
    let optionString = '?';
    let options = {
        autoplay: settings.autoplay ? 1 : 0,
        muted: settings.mute ? 1 : 0,
        autopause: 0,
        controls: settings.show_controls ? 1 : 0,
        loop: settings.loop ? 1: 0,
    }
    for (let k of Object.keys(options)) {
        optionString += k + '=' + options[k] + '&';
    }
    return {optionString:optionString, options:options};
}

function adjustElement($element, settings) {
    $element.parent().next(".zoomImg").remove();
    $element.siblings('.zoomImg').remove();
    $element.siblings('[role=presentation]').remove();
    addStyles(settings, $element);
}

function getPlayButton(settings) {
   let $surroundingCircle = $('<div>', {
       class:'vw-product-video-play-button'
   });
   $surroundingCircle.css('position', 'absolute');
   $surroundingCircle.css('padding', '20px');
   $surroundingCircle.css('border-radius', '100px');
   $surroundingCircle.css('z-index', '100');
   let $playButton = $('<div>');
   $playButton.css('width', '30px');
   $playButton.css('height', '30px');
   $playButton.css('border-style', 'solid');
   $playButton.css('border-width', '15px 0px 15px 30px');
   $playButton.css('box-sizing', 'border-box');
   $playButton.appendTo($surroundingCircle);
   return $surroundingCircle;
}

function applyAnchorCSS($anchor) {
    $anchor.css('display', 'flex');
    $anchor.css('align-items', 'center');
    $anchor.css('text-align', 'center');
    $anchor.css('justify-content', 'center');
    $anchor.css('width', '100%');
    $anchor.css('height', '100%');
}
function getImageElement($element) {
    let $img = $element.clone();
    $img.addClass('product-video-image-element')
    $img.css('width', '100%');
    $img.css('max-width', '100%');
    return $img;
}

function getInlineiFrame(url, settings){
    let $frame = $('<iframe>', {
        src: url,
        frameborder: '0',
        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowfullscreen: ''
    });
    $frame.css('position', 'absolute');
    $frame.css('top', '0');
    $frame.css('left', '0');
    $frame.css('width', '100%');
    $frame.css('height', '100%');
    return $frame;
}

function resetVideo($element, settings) {
    $($element).empty();
    initVideo($element, settings);
}

function unescapeHtml(safe) {
    return $('<div />').html(safe).text();
}

function addStyles(settings, element) {
    let $parent = $(element).parent();
    $parent.find('.vw-product-videos-styles').remove();
    $("<div />", {
        class: 'vw-product-videos-styles',
        html: '<style></style>'
    }).appendTo($parent);
    injectStyles(`
        .vw-product-video-play-button {
            background-color: ` + getColorString(settings.button_bgColor) + `;
        }

        .vw-product-video-play-button div {
            border-color: transparent transparent transparent ` + getColorString(settings.button_fgColor) + `;
        }

        .strp-window {
            background: transparent !important;
        }

        .lity, .strp-container {
            background-color: ` + getColorString(settings.bg_overlay) + ` !important; 
        }
    `, element);
}

function injectStyles(rule, element) {
    let $parent = $(element).parent();
    let html = $parent.find('.vw-product-videos-styles style').html();
    $parent.find('.vw-product-videos-styles style').html(html + ' ' + rule);
}

function getColorString(color) {
    let s = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
    return s; 
}

function resetStyles(element, settings) {
    addStyles(settings, element);
}

function parseBoolean(s) {
    if (s === 'true') {
        return true;
    }
    return false;
}

export { initVideo, resetVideo, resetStyles };
import React, { Component, CSSProperties } from 'react';
import { initVideo, resetVideo, resetStyles } from '../external/product-videos.js'
import { TextStyle, DisplayText} from '@shopify/polaris';
import '../external/lity.min.css';
import '../external/strip.css';

interface VideoPreviewProps {
  settings: any
}

interface VideoPreviewState {
  
}
class VideoPreview extends Component <VideoPreviewProps, VideoPreviewState> {
    
    ytEl:any;
    vimeoEl:any;
    videoEl:any;

    constructor(props: VideoPreviewProps) {
        super(props);
        this.ytEl = React.createRef();
        this.vimeoEl = React.createRef();
        this.videoEl = React.createRef();
    }

    componentDidMount() {
      this.init();
    }
  
    componentWillUnmount() {

    }

    init() {
      initVideo(this.ytEl, this.getYouTubePreviewSettings());
      initVideo(this.vimeoEl, this.getVimeoPreviewSettings());
      initVideo(this.videoEl, this.getVideoPreviewSettings());
    }

    reset(settings:any) {
      resetVideo(this.ytEl,  this.getYouTubePreviewSettings(settings));
      resetVideo(this.vimeoEl, this.getVimeoPreviewSettings(settings));
      resetVideo(this.videoEl, this.getVideoPreviewSettings(settings));
    }

    resetStyles(settings:any) {
      resetStyles(this.ytEl, this.getYouTubePreviewSettings(settings));
      resetStyles(this.vimeoEl, this.getVimeoPreviewSettings(settings));
      resetStyles(this.videoEl, this.getVideoPreviewSettings(settings));
    }

    getYouTubePreviewSettings(settings?:any) {
      let previewSettings = settings ? settings : {...this.props.settings};
      previewSettings.isPreview = true;
      previewSettings.src = 'https://cdn.shopify.com/s/files/1/0333/9904/6279/products/1_900x.jpg?v=1592560848'
      previewSettings.url = 'https://www.youtube.com/watch?v=_fJVpQwnr8M';
      return previewSettings;
    }

    getVimeoPreviewSettings(settings?:any) {
      let previewSettings = settings ? settings : {...this.props.settings};
      previewSettings.isPreview = true;
      previewSettings.src = 'https://cdn.shopify.com/s/files/1/0333/9904/6279/products/2copy_900x.jpg'
      previewSettings.url = 'https://vimeo.com/429576417';
      return previewSettings;
    }

    getVideoPreviewSettings(settings?:any) {
      let previewSettings = settings ? settings : {...this.props.settings};
      previewSettings.isPreview = true;
      previewSettings.src = 'https://cdn.shopify.com/s/files/1/0333/9904/6279/products/3_900x.jpg?v=1592798876'
      previewSettings.url = 'https://cdn.shopify.com/s/files/1/0333/9904/6279/files/plant.mp4?v=1592304451';
      return previewSettings;
    }


    render() {
      let externalDivStyles:CSSProperties = this.props.settings.display_type === 'inline' ? {width:'100%', height:'0', position:'relative', paddingBottom: '56.25%'} : {};

      return (
        <div>
          <h4 className='subheader'>YOUTUBE</h4>
          <div style={externalDivStyles} ref={el => this.ytEl = el} />
          <hr />
          <h4 className='subheader'>VIMEO</h4>
          <div style={externalDivStyles} ref={el => this.vimeoEl = el} />
          <hr />
          <h4 className='subheader'>VIDEO ELEMENT</h4>
          <div style={externalDivStyles} ref={el => this.videoEl = el} />
        </div>
      );
    }
  }

  export default VideoPreview;
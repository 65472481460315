import React, { Component } from 'react';
import { Card, List, TextStyle, DisplayText, Link, Layout } from '@shopify/polaris';

interface SupportProps {
    onChangeTab: Function
}

class Support extends Component <SupportProps> {

    handleOnChangeTab = (index: number) => {
        this.props.onChangeTab(index);
    }

    render() {
        return (
            <div>
                <Layout>
                    <Layout.Section>
                        <Card title="Frequently Asked Questions">
                            <Card.Section >
                            <List type="number">
                                <TextStyle variation="strong"><List.Item>Why can't I see the videos after I publish a new theme?</List.Item></TextStyle>
                                <p>By default, we install the code in the main published theme. When the published theme is changed, the code might not be present yet. All you have to do is to toggle the publish switch under 'Settings' off and on, and you are good to go.<br/><br/></p>
                                <TextStyle variation="strong"><List.Item>How do I upload video files to my Shopify admin?</List.Item></TextStyle>
                                <p>At your Shopify dashboard, go to on Settings &gt; Files. You can upload your video files there and copy the video URL. Shopify limits your file size to 20mb. <br/><br/></p>
                                <TextStyle variation="strong"><List.Item>I can't contact my developer and I don't understand your technical terms, are you able to help?</List.Item></TextStyle>
                                <p>Yes! Please contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on ProdVid">hello@voidworks.co</Link> and we will help you with this at no extra cost!<br/><br/></p>
                            </List>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card sectioned title="Contact Us">
                            <p>If you have any questions about ProdVid, we are here to help!</p>
                            <br />
                            <p>If you require further assistance or if you have any suggestions for improving the app, please contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on ProdVid">hello@voidworks.co</Link>!</p>
                        </Card>
                    </Layout.Section>
                  </Layout>
            </div>
        );
    }
}

export default Support;
import React, { Component } from 'react';
import { Card, FormLayout, Stack, Select, Checkbox, TextStyle } from '@shopify/polaris';
import ColorPicker from './ColorPicker';

interface SettingsEditorProps {
    onSettingsChange: Function,
    settings: any,
    display_type: string,
    button_bgColor: any,
    button_fgColor: any,
    bg_overlay: any,
    autoplay: boolean,
    mute:  boolean,
    show_controls:  boolean,
    loop: boolean
}

class SettingsEditor extends Component <SettingsEditorProps> {

    displayTypeOptions = [
        { label: 'Inline', value: 'inline' },
        //{ label: 'Minimal', value: 'minimal' }, 
        { label: 'Lightbox', value: 'lightbox' },
        { label: 'Sidebox', value: 'sidebox' },
    ];

    handleSettingsChange = (selected:any, id:string) => {
        this.props.onSettingsChange(selected, id);
    }

    render() {
        return (
            <div>
                <Card title="Video Player Display">
                    <Card.Section>
                        <Select id="display_type" label="Display Type" options={this.displayTypeOptions} onChange={this.handleSettingsChange} value={this.props.display_type} />
                    </Card.Section>
                </Card>
                <Card title="Video Player Settings">
                    <Card.Section>
                        <p><TextStyle variation="subdued">Autoplay might not be supported on some platforms and/or devices, especially when it is not muted.</TextStyle></p>
                        <Stack>
                            <Checkbox id="mute" label="Mute" onChange={this.handleSettingsChange} checked={this.props.mute} />
                        </Stack>
                        <Stack>
                            <Checkbox id="autoplay" label="Autoplay" onChange={this.handleSettingsChange} checked={this.props.autoplay} />
                        </Stack>
                        <Stack>
                            <Checkbox id="show_controls" label="Show Controls" onChange={this.handleSettingsChange} checked={this.props.show_controls} />
                        </Stack>
                        <Stack>
                            <Checkbox id="loop" label="Loop" onChange={this.handleSettingsChange} checked={this.props.loop} />
                        </Stack>
                    </Card.Section>
                </Card>
                <Card title="Lightbox Style">
                    <Card.Section title='Button'>
                        <Stack>
                            <ColorPicker id="button_bgColor" onChange={this.handleSettingsChange} label="Background Color" color={this.props.button_bgColor}></ColorPicker>
                            <ColorPicker id="button_fgColor" onChange={this.handleSettingsChange} label="Icon Color" color={this.props.button_fgColor}></ColorPicker>
                        </Stack>
                    </Card.Section>
                    <Card.Section title='Pop ups'>
                        <Stack>
                            <ColorPicker id="bg_overlay" bottomPosition={true} onChange={this.handleSettingsChange} label="Background Overlay Color" color={this.props.bg_overlay}></ColorPicker>
                        </Stack>
                    </Card.Section>
                </Card>
            </div>
        );
    }
}

export default SettingsEditor;
import React, { Component } from 'react';
import {Provider} from '@shopify/app-bridge-react';
import {AppProvider, Page} from '@shopify/polaris';
import Settings from '../components/Settings';
import { getFetch } from '../helpers/request';

interface SettingsPageProps {
  match: any
}

interface SettingsPageState {
  shopOrigin: string,
  apiKey: string
}

class SettingsPage extends Component <SettingsPageProps, SettingsPageState> {

  constructor(props: SettingsPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin, apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from(`${this.state.shopOrigin}/admin`).toString('base64')};
    let tabIndex = this.props.match.params.tabIndex === undefined ? 0 : this.props.match.params.tabIndex;
    return (
      this.state.shopOrigin != '' && this.state.apiKey != '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Page title="">
            <Settings selectedTabIndex={parseInt(tabIndex)}></Settings>
          </Page>
        </Provider>
      </AppProvider>
    );
  }
}

export default SettingsPage;

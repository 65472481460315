import React, { Component } from 'react';
import './App.css';
import '@shopify/polaris/dist/styles.css';

import { BrowserRouter as Router, Route } from "react-router-dom";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";

interface AppProps {

}

interface AppState {

}

class App extends Component <AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <Router>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/settings" component={SettingsPage} />
      </Router>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { Modal } from '@shopify/polaris';

import screen0 from '../images/screen0.jpg';
import screen1 from '../images/screen1.jpg';
import screen2 from '../images/screen2.jpg';


interface Props {

}

class HelpInfoModal extends Component {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal.Section>
                <p>You will need to add a video URL at your Shopify product image's alt text. You can insert any video links e.g. Youtube, Vimeo or video files uploaded in your Shopify store.</p>
                <hr/>
                <p><strong>Insert video URL into Product image's alt text:</strong></p>
                <br/>
                <p>1. From your Shopify admin, go to <strong>Products &gt; All products.</strong></p>
                <br/>
                <p>2. Click the name of the product that you want to add videos to.</p>
                <br/>
                <p>3.  Under <strong>Media</strong>, click on the product to see its details page or upload a image you want to add a video to, and click on its details page. <i>This image will be the thumbnail for the video player on your Product page.</i></p>
                <img className="full-width" src={screen0} />
                <br/>
                <p>4. Click <strong>Add alt text.</strong></p>
                <br/>
                <p>5. Enter the video URL at the alt text, and then click <strong>Save alt text</strong>.</p>
                <img className="full-width" src={screen1} />
                <br/>
                <p><strong>TIP</strong></p>
                <p>Make sure your video link is not shortened. The url should start with <strong>https://www.youtube.com/</strong> (if YouTube) or <strong>https://www.vimeo.com/</strong> (if Vimeo) or <strong>https://</strong> (if hosted on Shopify or elsewhere, .mp4 format recommended for maximum compatibility).</p>
                <hr/>
                <p>
                Your video element should appear on your Product page if you have turned on publish at dashboard. </p>
                <img className="full-width" src={screen2} />
            </Modal.Section>
        )
    }
}

export default HelpInfoModal;